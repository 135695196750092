<template>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card title mb-0">
                <div class="card-body title-filter">
                    <div class="row">
                        <div class="col-lg-6 title-filte-left">
                            <h1 class="title-filter-title">
                                <b-icon icon="gear-fill" class='b-table-icon' aria-hidden="true"></b-icon>
                                <p class="pl-1">Phân quyền cho <span class="text-danger">{{group.name}}</span></p>
                            </h1>
                        </div>
                        <div class="col-lg-6 title-filte-right">
                            <a href="#/setting/roles" class="btn btn-default btn-sm mr-2 ml-2">
                                <i class="fa fa-arrow-left" ></i> Quay lại
                            </a>

                            <button type="button" class="btn btn-outline-gray btn-sm" @click="checkedAll">
                                <span v-if="!selected"><i class="fa fa-check-square" ></i> Chọn tất cả</span>
                                <span v-if="selected"><i class="fa fa-square" ></i> Bỏ chọn</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <staff v-if="group.id" :group="group" />
        </div>
        <div class="col-md-8">
            <div class="card title">
                <div class="card-footer title-footer clearfix p-2 title-footer-fixed text-center">
                    <a href="#/setting/roles" class="btn btn-default btn-sm mr-2 ml-2">
                        <i class="fa fa-arrow-left" ></i> Quay lại
                    </a>
                    <button :disabled="saveLoading" type="button" class="btn btn-outline-gray btn-sm" @click="save">
                        <b-spinner v-if="saveLoading" small></b-spinner>
                        <i v-else class="fa fa-save" ></i>
                        Cấp quyền
                    </button>
                </div>
            </div>
            <div class="card title" v-for="service in services" :key="service.name" >
                <div class="card-body title-body pb-2 pl-3 pr-3">
                    <h3 class="tile-title is-line mb-2 pointer" @click="checked(service)" >
                        <i v-if="service.checked" class="fa fa-check-square"></i>
                        <i v-else class="far fa-square pointer"></i>
                        {{service.alias}} 
                        <span class="btn-add float-right pr-2">
                            <i>[{{service.name}}]</i>
                        </span>
                    </h3>
                    <div class="row mt-2 mb-2 text-md" v-for="child in service.child" :key="child.name">
                        <div class="col-md-6 pointer " @click="child.checked=!child.checked">
                            <i v-if="child.checked" class="fa fa-check-square"></i>
                            <i v-else class="far fa-square"></i>
                            {{child.alias}}
                        </div>
                        <div class="col-md-6 text-right">
                            <span class="text-muted" >{{child.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'lodash';
    import Staff from './RoleStaff';

    export default {
        props: ['id'],
        components: {Staff},
        data: function () {
            return {
                group: {},
                activeOptions: [
                    {value: 0, text: "Tạm khoá"},
                    {value: 1, text: "Hoạt động"}
                ],
                service: '/auth-group',
                saveLoading: false,
                listLoading: false,
                selected: false,
                services: []
            };
        },
        methods: {
            checked: function (service) {
                service.checked = !service.checked;
                for (let i = 0; i < this.services.length; i++) {
                    if (service.name === this.services[i].name) {
                        for (let j = 0; j < this.services[i].child.length; j++) {
                            this.services[i].child[j].checked = this.services[i].checked;
                        }
                    }
                }
            },
            checkedAll: function () {
                this.selected = !this.selected;
                for (var i = 0; i < this.services.length; i++) {
                    this.services[i].checked = this.selected;
                    for (var j = 0; j < this.services[i].child.length; j++) {
                        this.services[i].child[j].checked = this.selected;
                    }
                }
            },
            close: function () {
                this.showForm = false;
            },
            load: function () {
                this.showForm = true;
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);
                this.$service.get(`${this.service}/role?id=${this.id}`, {
                    params: this.filterFormModel
                }).then(response => {
                    for (let i = 0; i < response.data.services.length; i++) {
                        response.data.services[i].checked = true;
                        for (let j = 0; j < response.data.services[i].child.length; j++) {
                            response.data.services[i].child[j].checked = false;
                            for (let k = 0; k < response.data.groupItems.length; k++) {
                                if (response.data.groupItems[k].name === response.data.services[i].child[j].name) {
                                    response.data.services[i].child[j].checked = true;
                                }
                            }
                            if (!response.data.services[i].child[j].checked) {
                                response.data.services[i].checked = false;
                            }
                        }
                    }
                    this.services = response.data.services;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    clearTimeout(loadingDelay);
                    this.listLoading = false;
                });
            },
            save: function () {
                let formModel = new Object();
                formModel.groupId = this.group.id;
                formModel.roles = [];

                for (var i = 0; i < this.services.length; i++) {
                    for (var j = 0; j < this.services[i].child.length; j++) {
                        if (this.services[i].child[j].checked) {
                            formModel.roles.push(this.services[i].child[j].name);
                        }
                    }
                }
                if (formModel.roles.length === 0) {
                    this.$swal({
                        title: "Chọn hành động cho quyền này!",
                        icon: 'warning'
                    });
                    return false;
                }
                this.saveLoading = true;
                return this.$service({url: `${this.service}/assignment?id=${this.group.id}`, method: 'put', data: formModel}).then(response => {
                    this.load();
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: "Cấp quyền thành công",
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: 'Cấp quyền thất bại',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    }
                    return response.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            }
        },
        mounted() {
            this.$service.get(`${this.service}/get?id=${this.id}`).then(response => {
                this.group = response.data;
                this.load();
            });
        }
    }
</script>