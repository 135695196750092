<template>
    <div class="row">
        <div class="col-12">
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" :isHeaderTable='false'
                     :is-header="false" :is-footer="false" :is-selected="false" @load="load" title="nhân viên" class="mt-3">
                <template slot="title">Danh sách nhân viên</template>
                <template #cell(name)="{item}">
                    <span :class="((Date.now() - item.last_online_time) <= 60 * 15 * 1000 ? 'c-signal is-success' : 'c-signal')"><span class="h-word-break">{{item.name}}</span></span>
                </template>
                <template #cell(blocked)="{item}">
                    <i v-if="item.blocked" class="small text-danger" >Tạm khoá</i>
                    <i v-else class="small text-success" >Hoạt động</i>
                </template>
            </g-table>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";

    export default {
        props: ['group'],
        mixins: [list],
        data: function () {
            return {
                service: '/staff',
                fields: [
                    {key: 'name', label: "Tên nhân viên"},
                    {key: 'blocked', label: "Trạng thái"}
                ],
                defaultFilterFormModel: {
                    page: 1,
                    size: 50,
                    group: this.group.id
                }
            };
        },
        methods: {
        },
        mounted() {
        }
    }
</script>